$(document).on('ready', function () {
  /* global ajax_object */
  /* Show Permissions on role select change */
  // $(document).on(
  //   'change paste keyup',
  //   '.register-new-user .register-new-user__name input, .register-new-user .register-new-user__email input ',
  //   function () {
  //     validateForm();
  //   }
  // );
  $(
    '<p class="d-none input-error text-danger">Please enter valid name.</p>'
  ).insertAfter('.register-new-user .register-new-user__name input');
  $('.register-new-user .register-new-user__name input').on(
    'blur',
    function () {
      if ($(this).val() === '' || $(this).val().length < 3) {
        if (!$(this).parent().hasClass('with-error')) {
          $(this)
            .parent()
            .addClass('with-error')
            .append(
              '<div class="error-msg"><span>Please provide a valid value.</span></div>'
            );
          $('.register-user-block__footer button, button[type="submit"]').attr(
            'disabled',
            true
          );
        }
      } else {
        $(this).parent().removeClass('with-error').find('.error-msg').remove();
        $('.register-user-block__footer button, button[type="submit"]').attr(
          'disabled',
          false
        );
      }
    }
  );

  /* Validate input length */
  $('.register-new-user .register-new-user__name input').on(
    'input',
    function () {
      if ($(this).val().length > 64) {
        let value = $(this).val();
        $(this).val(value.substring(0, 64));
      }
    }
  );

  $('.register-new-user .register-new-user__email input').on(
    'blur',
    function () {
      let email = $(this);
      let regExpEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let strings = ['.ru', '.ua', '@mailinator.com', '@yandex.com'];

      strings.forEach(function (el) {
        if (email.val().includes(el)) {
          if (!email.parent().hasClass('with-error')) {
            email
              .parent()
              .addClass('with-error')
              .append(
                '<div class="error-msg"><span>Please provide a valid email.</span></div>'
              );
            $(
              '.register-user-block__footer button, button[type="submit"]'
            ).attr('disabled', true);
          }
        } else {
          email.parent().removeClass('with-error').find('.error-msg').remove();
          $('.register-user-block__footer button, button[type="submit"]').attr(
            'disabled',
            false
          );
        }
      });

      if (
        (!regExpEmail.test(String(email.val().toLowerCase())) &&
          email.val() !== '') ||
        email.val() === ''
      ) {
        if (!email.parent().hasClass('with-error')) {
          email
            .parent()
            .addClass('with-error')
            .append(
              '<div class="error-msg"><span>Please provide a valid email.</span></div>'
            );
          $('.register-user-block__footer button, button[type="submit"]').attr(
            'disabled',
            true
          );
        }
      } else {
        email.parent().removeClass('with-error').find('.error-msg').remove();
        $('.register-user-block__footer button, button[type="submit"]').attr(
          'disabled',
          false
        );
      }
    }
  );

  /* Validate Email length */
  $('.register-new-user .register-new-user__email input').on(
    'input',
    function () {
      if ($(this).val().length > 64) {
        let value = $(this).val();
        $(this).val(value.substring(0, 64));
      }
    }
  );

  /* Set permission value */

  $(document).on('click', '.js-user-role .custom-select__item', function () {
    const value = $(this).data('value');
    $('#register-new-user').find('input[name="roles"]').val(value);
    $(this)
      .closest('.js-user-role')
      .siblings('.role-capabilities')
      .removeClass('active');
    $(this)
      .closest('.js-user-role')
      .siblings('#' + value)
      .addClass('active');
  });

  $(document).on(
    'click',
    '.js-user-role-edit .custom-select__item',
    function () {
      const value = $(this).data('value');
      $('#edit-user').find('input[name="roles"]').val(value);
      $(this)
        .closest('.js-user-role-edit')
        .siblings('.role-capabilities')
        .removeClass('active');
      $(this)
        .closest('.js-user-role-edit')
        .siblings('#' + value)
        .addClass('active');
    }
  );

  /* Invite New user */

  $('#register-new-user').on('submit', function (event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('action', 'invite_user');
    const options = {
      method: 'POST',
      body: formData,
    };

    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          $('.user-created-message').show();
          $('.user-created-message .user-created-message__text').html(
            data.data.message
          );
          if (data.data.additional) {
            $(':input')
              .not(':button, :submit, :reset, :hidden')
              .val('')
              .prop('checked', false);
            setTimeout(function () {
              $('.user-created-message').hide();
            }, 1000);
            $('#register-new-user button[type="submit"]').attr(
              'disabled',
              true
            );
          } else {
            setTimeout(function () {
              let domain = document.location.origin;
              document.location.href = domain + '/admin/users/';
            }, 1000);
          }
        } else {
          alert(data.data);
        }
      });
  });
});

const validateForm = () => {
  if (
    $('.register-new-user .register-new-user__name input').length &&
    $('.register-new-user' + ' .register-new-user__name input').is(':visible')
  ) {
    validateInput($('.register-new-user .register-new-user__name input'));
  }
  validateEmail($('.register-new-user .register-new-user__email input'));
  if ($('.with-error').length === 0) {
    $('.register-user-block__footer button, button[type="submit"]').attr(
      'disabled',
      false
    );
  } else {
    $('.register-user-block__footer button, button[type="submit"]').attr(
      'disabled',
      true
    );
  }
};

const validateEmail = (input) => {
  input.each(function () {
    // validate for email field
    let email = $(this);
    let regExpEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let strings = ['.ru', '.ua', '@mailinator.com', '@yandex.com'];

    strings.forEach(function (el) {
      if (email.val().includes(el)) {
        if (!email.parent().hasClass('with-error')) {
          email
            .parent()
            .addClass('with-error')
            .append(
              '<div class="error-msg"><span>Please provide a valid email.</span></div>'
            );
        }
      } else {
        email.parent().removeClass('with-error').find('.error-msg').remove();
      }
    });

    if (
      (!regExpEmail.test(String(email.val().toLowerCase())) &&
        email.val() !== '') ||
      email.val() === ''
    ) {
      if (!email.parent().hasClass('with-error')) {
        email
          .parent()
          .addClass('with-error')
          .append(
            '<div class="error-msg"><span>Please provide a valid email.</span></div>'
          );
      }
    } else {
      email.parent().removeClass('with-error').find('.error-msg').remove();
    }
  });
};

const validateInput = (input) => {
  input.each(function () {
    if ($(this).val() === '' || $(this).val().length < 3) {
      if (!$(this).parent().hasClass('with-error')) {
        $(this)
          .parent()
          .addClass('with-error')
          .append(
            '<div class="error-msg"><span>Please provide a valid value.</span></div>'
          );
      }
    } else {
      $(this).parent().removeClass('with-error').find('.error-msg').remove();
    }
  });
};
