$(document).on('ready', function () {
  /* global ajax_object */
  $(document).on('click', '.js-update-workspace', function (e) {
    e.preventDefault();
    const dataForm = new FormData($('#update-workspace')[0]);
    dataForm.append('action', 'update-workspace-name');
    const options = {
      method: 'POST',
      body: dataForm,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          $('.workspace-rename .modal-footer').remove();
          $('.workspace-rename .modal-body').html(data.data.message);
          setTimeout(function () {
            window.location.href = data.data.redirect_url;
          }, 1500);
        } else {
          alert(data.data);
        }
      });
  });

  $(document).on('click', '.js-create-workspace', function (e) {
    e.preventDefault();
    const dataForm = new FormData($('#add-workspace-form')[0]);
    dataForm.append('action', 'add-workspace');
    const options = {
      method: 'POST',
      body: dataForm,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          $('#add-workspace .modal-footer').remove();
          $('#add-workspace .modal-body')
            .removeClass('text-right')
            .addClass('text-left')
            .html(data.data.message);
          setTimeout(function () {
            window.location.href = data.data.redirect_url;
          }, 1500);
        } else {
          alert(data.data);
        }
      });
  });

  /* Articles pagination */
  $(document).on(
    'change',
    '.js-workspace-page .custom-pagination__input input',
    function () {
      const page = $(
        '.js-workspace-page .custom-pagination__input input'
      ).val();
      const url = $(this).closest('.custom-pagination').data('url');
      const isFiltered = $(this)
        .closest('.custom-pagination')
        .hasClass('filtered');

      if (!isFiltered) {
        window.location.href = url + '/page/' + page;
      } else {
        const $activeFilter = $('.js-srtable-filter-item.active');
        const filterType = $activeFilter.data('filter');
        const workspace = $('.srtable-grid__container').data('workspace');
        const order = $activeFilter.hasClass('clicked') ? 'ASC' : 'DESC';
        const orderParams = new FormData();
        orderParams.append('action', 'order_editions');
        orderParams.append('filter_type', filterType);
        orderParams.append('order', order);
        orderParams.append('workspace', workspace);
        orderParams.append('page', page);
        const options = {
          method: 'POST',
          body: orderParams,
        };
        fetch(ajax_object.ajax_url, options)
          .then((response) => response.json())
          .then((data) => {
            $('#srtable-grid').html(data.html);
          });
      }
    }
  );

  /* Open Edition on item click */

  $(document).on('click', '.srtable-grid__grid', function (event) {
    if (
      event.target.classList.value.includes('srtable-grid__grid') ||
      event.target.parentElement.classList.value.includes('srtable-grid__inner')
    ) {
      window.location.href = $(this).data('link');
    }
  });

  function callEditionsFilter(applyValues, filterType) {
    let sideBarFilters = [
      'status',
      'assignment',
      'tags',
      'assignment-multiple',
    ];
    const index = sideBarFilters.indexOf(filterType);
    sideBarFilters.splice(index, 1); //remove current filter type from the filters list
    let orderType = 'title';
    let order = 'DESC';
    if ($('.js-srtable-filter-item.active').length) {
      orderType = $('.js-srtable-filter-item.active').data('filter');
    }
    if ($('.js-srtable-filter-item.active.clicked').length) {
      order = 'ASC';
    }
    const filterParams = new FormData();
    filterParams.append('action', 'order_editions');
    filterParams.append('filter_type', orderType);
    filterParams.append('order', order);
    filterParams.append(
      'workspace',
      $('.srtable-grid__container').data('workspace')
    );

    if (applyValues !== '') {
      filterParams.append(filterType, applyValues);
    }

    sideBarFilters.map((item) => {
      let sidebarOtherFilter = $(
        '.checkbox-container[data-filter="' + item + '"]'
      ).data('values');

      if (sidebarOtherFilter) {
        /* Append another filters type */
        filterParams.append(item, sidebarOtherFilter);
      }
    });

    const options = {
      method: 'POST',
      body: filterParams,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        $('#srtable-grid').html(data.html);
        $('.js-workspace-page').addClass('d-none'); //Temporary solution. Need to update on filters apply
      });
  }

  /* Apply Filters */
  /* Apply multiple-assignment filter on data change */
  const targetElements = document.getElementsByClassName('js-multiselect');
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'data-value'
      ) {
        const newValue = mutation.target.getAttribute('data-value');
        const classList = mutation.target.classList;
        if (classList.value.includes('active')) {
          $('.assigned_user_multiselect').data('values', newValue);
          callEditionsFilter(newValue, 'assignment-multiple');
        }
      }
    });
  });

  if (targetElements.length > 0) {
    for (let i = 0; i < targetElements.length; i++) {
      observer.observe(targetElements[i], { attributes: true });
    }
  }

  /* Apply filter on click */
  $(document).on('click', '.checkbox-div', function () {
    let filterType = $(this).closest('.checkbox-container').data('filter');
    let value;
    let currentValues;
    if ($(this).hasClass('multiselect')) {
      value = $(this)[0].dataset.value.split(',');
    } else {
      value = String($(this).data('value'));
    }
    const valueType = typeof value;
    if (valueType === 'string') {
      currentValues = $(this)
        .closest('.checkbox-container')
        .data('values')
        .split(',');
      currentValues = $.grep(currentValues, (n) => n === 0 || n);
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        currentValues.push(value);
      } else {
        const index = currentValues.indexOf(value);
        if (index > -1) {
          // only splice array when item is found
          currentValues.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      currentValues = currentValues.join(',');
    } else {
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        currentValues = value.join(',');
      } else {
        currentValues = '';
      }
    }
    $(this).closest('.checkbox-container').data('values', currentValues);

    callEditionsFilter(currentValues, filterType);
  });

  /* Apply tags filter */
  $(document).on('click', '.js-tag-select', function () {
    let filterType = $(this).closest('.checkbox-container').data('filter');
    let value = String($(this).data('tag'));
    let currentValues = $(this)
      .closest('.checkbox-container')
      .data('values')
      .split(',');
    currentValues = $.grep(currentValues, (n) => n === 0 || n);
    $(this).toggleClass('active');
    if ($(this).hasClass('active')) {
      currentValues.push(value);
    } else {
      const index = currentValues.indexOf(value);
      if (index > -1) {
        // only splice array when item is found
        currentValues.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    currentValues = currentValues.join(',');
    $(this).closest('.checkbox-container').data('values', currentValues);
    callEditionsFilter(currentValues, filterType);
  });
});

let keyupTimer;
$(document).on('keyup', '.js-search-edition', function (e) {
  e.preventDefault();
  if ($(this).val() !== '') {
    clearTimeout(keyupTimer);
    keyupTimer = setTimeout(function () {
      $('.search-icon div').removeClass('d-none');
      $('.search-icon i').addClass('d-none');
      const dataForm = new FormData($('#search-edition-list')[0]);
      dataForm.append('action', 'search-edition-list');
      const options = {
        method: 'POST',
        body: dataForm,
      };
      fetch(ajax_object.ajax_url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            $('.search-results').html(data.data.editions).removeClass('d-none');
            $('.search-icon div').addClass('d-none');
            $('.search-icon i').removeClass('d-none');
          } else {
            $('.search-results').addClass('d-none');
            alert(data.data);
          }
        });
    }, 500);
  } else {
    $('.search-results').addClass('d-none');
  }
});
