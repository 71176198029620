import React from 'react';
import PropTypes from 'prop-types';

import { ButtonWrapper } from './styled';

const Button = ({
  // isMobile,
  blockType,
  icon,
  title,
  url,
  target,
  rel,
  style,
  size,
  dataSet,
  attrSet,
  callback,
  disabled = false,
}) => {
  let type = 'button';

  let props = {
    className: ['button', style ? style : false, size ? size : false]
      .filter(Boolean)
      .join(' '),
  };

  if (url) {
    props.href = url;
    type = 'a';
  }
  if (target) props.target = target;
  if (rel) props.rel = rel;

  if (callback && typeof callback == 'function') {
    props.onClick = () => callback(dataSet);
  }

  if (typeof dataSet !== 'undefined' && Object.keys(dataSet).length > 0) {
    let newDataSet = new Object();
    for (let dataProp in dataSet) {
      Object.assign(newDataSet, { [`data-${dataProp}`]: dataSet[dataProp] });
    }

    props = { ...props, ...newDataSet };
  }
  if (typeof attrSet !== 'undefined' && Object.keys(attrSet).length > 0) {
    let newAttrSet = new Object();
    for (let attrProp in attrSet) {
      Object.assign(newAttrSet, { [`${attrProp}`]: attrSet[attrProp] });
    }

    props = { ...props, ...newAttrSet };
  }

  if (disabled) {
    props = { ...props, disabled: true };
  }

  let icon_class = icon !== '' ? 'icon-button' : '';

  return (
    <ButtonWrapper className={`button ${blockType} ${icon_class}`}>
      {icon !== '' ? <img src={`${icon}`} alt="Upload" className="icon" /> : ''}
      {React.createElement(
        type,
        props,
        decodeEntities(title || 'no text provided')
      )}
    </ButtonWrapper>
  );
};

function decodeEntities(encodedString) {
  let textArea = document.createElement('textarea');
  textArea.innerHTML = encodedString;
  return textArea.value;
}

Button.defaultProps = {
  style: 'primary',
  blockType: 'block',
  icon: '',
};

Button.propTypes = {
  isMobile: PropTypes.bool,
  blockType: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  style: PropTypes.string,
  size: PropTypes.string,
  dataSet: PropTypes.object,
  attrSet: PropTypes.object,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
