$(document).on('ready', function () {
  /* global ajax_object */
  /* Show Permissions on role select change */
  validateForm();

  $(document).on(
    'change paste keyup',
    '.register-new-edition__title input, .register-new-edition__date input',
    function () {
      validateForm();
    }
  );

  /* Create New edition */

  $('#add-new-edition').on('submit', function (event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('action', 'add_edition');
    const options = {
      method: 'POST',
      body: formData,
    };

    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          $('.edition-created-message').show();
          $('.edition-created-message .edition-created-message__text').html(
            data.data.message
          );
          setTimeout(function () {
            if (data.data.redirect) {
              window.location.href = data.data.redirect;
            } else {
              location.reload(true);
            }
          }, 1000);
        } else {
          alert(data.data);
        }
      });
  });

  /* Save Edition as draft */
  $('.js-save-draft-edition a.save-draft').on('click', function () {
    let formValues = $('#add-new-edition').serializeArray();
    let allowSubmit = true;
    formValues.forEach((element) => {
      if (
        (element.name == 'title' && element.value === '') ||
        element.length < 3
      ) {
        $('.register-new-edition__title input')
          .closest('.input-field')
          .addClass('with-error')
          .append(
            '<div class="error-msg"><span>Please provide a valid value.</span></div>'
          );
        allowSubmit = false;
      } else if (element.name == 'date' && element.value === '') {
        $('.register-new-edition__date input')
          .closest('.input-field')
          .addClass('with-error')
          .append(
            '<div class="error-msg"><span>Please provide a valid date.</span></div>'
          );
        allowSubmit = false;
      }
    });

    if (allowSubmit) {
      const formData = new FormData($('#add-new-edition')[0]);
      formData.append('action', 'add_edition');
      formData.append('save_draft', 'true');
      const options = {
        method: 'POST',
        body: formData,
      };

      fetch(ajax_object.ajax_url, options)
        .then((response) => response.json())
        .then((data) => {
          $(this).find('.spinner-border').removeClass('d-none'); // show loader
          if (data.success) {
            $('.edition-created-message').show();
            $('.edition-created-message .edition-created-message__text').html(
              data.data.message
            );
            setTimeout(function () {
              if (data.data.redirect) {
                window.location.href = data.data.redirect;
              } else {
                location.reload(true);
              }
            }, 1000);
          } else {
            $(this).find('.spinner-border').addClass('d-none'); // hide loader
            alert(data.data);
          }
        });
    }
  });

  /* Edit edition */

  $(document).on('click', '.js-edit-edition-form', function () {
    $(this).addClass('d-none');
    $('.modifying-form__footer').removeClass('d-none');
    $('.input-info').toggleClass('active');
  });

  /* Delete Edition */

  $('#delete-edition').on('show.bs.modal', function (event) {
    console.log('MODAL SHOWED');
    console.log(event.relatedTarget);
    let button = $(event.relatedTarget);
    let edition_id = button.data('edition-id');
    let workspace_id = button.data('workspace');
    let modal = $(this);
    modal
      .find('.modal-body .js_remove_edition')
      .attr('data-edition-id', edition_id)
      .attr('data-workspace', workspace_id);
  });

  $('.js_remove_edition').on('click', function (event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('edition_id', $(this).data('edition-id'));
    if ($(this).data('workspace')) {
      formData.append('workspace', $(this).data('workspace'));
    }
    formData.append('action', 'delete-edition');
    const options = {
      method: 'POST',
      body: formData,
    };

    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if ($('body').hasClass('single-edition')) {
            $('.edition-created-message').show();
            $('.edition-created-message .edition-created-message__text').html(
              data.data.message
            );
          } else {
            $('.workspace-created-message').show();
            $(
              '.workspace-created-message .workspace-created-message__text'
            ).html(data.data.message);
          }
          setTimeout(function () {
            if (data.data.redirect) {
              window.location.href = data.data.redirect;
            } else {
              location.reload(true);
            }
          }, 1000);
        } else {
          alert(data.data);
        }
      });
  });
});

const validateForm = () => {
  validateInput($('.register-new-edition__title input'));
  validateDate($('.register-new-edition__date input'));

  if ($('#add-new-edition').length) {
    if ($('.with-error').length === 0) {
      $('button[type="submit"]').attr('disabled', false);
    } else {
      $('button[type="submit"]').attr('disabled', true);
    }
  }
};

const validateInput = (input) => {
  input.each(function () {
    if ($(this).val() === '' || $(this).val().length < 3) {
      if (!$(this).closest('.input-field').hasClass('with-error')) {
        const errorMsg = $(this).closest('.input-field').data('error');
        let errorHTML =
          '<div class="error-msg"><span>Please provide a valid value.</span></div>';
        if (errorMsg) {
          errorHTML =
            '<div class="error-msg"><span>' + errorMsg + '</span></div>';
        }
        $(this)
          .closest('.input-field')
          .addClass('with-error')
          .append(errorHTML);
      }
    } else {
      $(this)
        .closest('.input-field')
        .removeClass('with-error')
        .find('.error-msg')
        .remove();
    }
  });
};

const validateDate = (input) => {
  input.each(function () {
    if ($(this).val() === '') {
      if (!$(this).parent().hasClass('with-error')) {
        $(this)
          .parent()
          .addClass('with-error')
          .append(
            '<div class="error-msg"><span>Please provide a valid date.</span></div>'
          );
      }
    } else {
      $(this).parent().removeClass('with-error').find('.error-msg').remove();
    }
  });
};
