import ReactHabitat from 'react-habitat';
import toPairs from 'ramda/es/toPairs';
import DomFactory from './DomFactory';
import { getDuplicateKeysString, objectsShareKey } from './utils';

const getContexts = () => [
  require.context(
    'scripts/components/',
    true,
    /scripts\/components\/.*habitat\.js$/
  ),
];

const getAsyncContexts = () => [
  require.context(
    'scripts/components/',
    true,
    /scripts\/components\/.*habitat-async\.js$/
  ),
];

const getRegisteredComponents = (contexts) =>
  contexts.flatMap((context) =>
    context.keys().map((key) => context(key).default)
  );

export default class ReactApp extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const contexts = getContexts();
    const asyncContexts = getAsyncContexts();

    this.componentSelector = 'data-component';
    this.updateComponents(contexts, asyncContexts);

    if (module.hot) {
      const dependencies = [...contexts, ...asyncContexts].map((c) => c.id);

      module.hot.accept(dependencies, () => {
        this.dispose();
        this.updateComponents(getContexts(), getAsyncContexts());
      });
    }
  }

  updateComponents(contexts, asyncContexts) {
    // Create a new container builder
    const builder = new ReactHabitat.ContainerBuilder();

    builder.factory = new DomFactory();

    const components = getRegisteredComponents(contexts);
    const asyncComponents = getRegisteredComponents(asyncContexts);

    const allComponents = [...components, ...asyncComponents];

    const componentPairs = components.flatMap(toPairs);
    const asyncComponentPairs = asyncComponents.flatMap(toPairs);

    if (
      process.env.NODE_ENV !== 'production' &&
      objectsShareKey(allComponents)
    ) {
      const duplicateKeys = getDuplicateKeysString(allComponents);
      // eslint-disable-next-line
      console.error(
        `react-habitat: there are duplicate components registered under the following names: ${duplicateKeys}`,
        '\nplease check your habitat.js and habitat-async.js files!'
      );
    }

    // Register components with react habitat
    componentPairs.forEach(([name, component]) =>
      builder.register(component).as(name)
    );
    asyncComponentPairs.forEach(([name, component]) =>
      builder.registerAsync(component).as(name)
    );

    // Finally, set the container
    this.setContainer(builder.build());
  }
}
