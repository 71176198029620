import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Container from './container';
import PropTypes from 'prop-types';

const DND = ({
  containerClassName,
  cardClassName,
  images,
  setSelectedImages,
  setImageToEdit,
  imageToEdit,
  setScalePoint,
  scalePoint,
  setImageToDelete,
  setOpenWarning,
  setImageToEditIndex,
  setSelectedImage,
  setImagesArray,
}) => {
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Container
          className={containerClassName}
          cardClassName={cardClassName}
          images={images}
          setSelectedImages={setSelectedImages}
          setImageToEdit={setImageToEdit}
          imageToEdit={imageToEdit}
          setScalePoint={setScalePoint}
          scalePoint={scalePoint}
          setImageToDelete={setImageToDelete}
          setOpenWarning={setOpenWarning}
          setImageToEditIndex={setImageToEditIndex}
          setSelectedImage={setSelectedImage}
          setImagesArray={setImagesArray}
        />
      </DndProvider>
    </>
  );
};

DND.propTypes = {
  containerClassName: PropTypes.string,
  cardClassName: PropTypes.string,
  images: PropTypes.array,
  setSelectedImages: PropTypes.func,
  setImageToEdit: PropTypes.func,
  imageToEdit: PropTypes.string,
  scalePoint: PropTypes.number,
  setScalePoint: PropTypes.func,
  setImageToDelete: PropTypes.func,
  setOpenWarning: PropTypes.func,
  setImageToEditIndex: PropTypes.func,
  setSelectedImage: PropTypes.func,
  setImagesArray: PropTypes.func,
};
export default DND;
