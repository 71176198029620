import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;

  &.inline-block {
    display: inline-block;
    width: auto;

    .button {
      min-width: auto;
    }
  }

  &.icon-button {
    display: flex;
    align-items: center;

    .icon {
      position: absolute;
      left: 15px;
    }

    .button {
      padding-left: 40px;
    }
  }

  button:disabled {
    opacity: 0.7;
  }

  //.button {
  //  min-width: 250px;
  //  @media (max-width: 768px) {
  //    min-width: 0;
  //  }
  //}
`;
