$(document).on('ready', function () {
  /* global ajax_object */
  $(document).on('click', '.js_move_edition', function (e) {
    e.preventDefault();
    const workspace = new FormData();
    workspace.append('workspace_id', $(this).data('workspace-id'));
    workspace.append('edition_id', $(this).data('post-id'));
    workspace.append('action', 'update-editions-workspace');
    const options = {
      method: 'POST',
      body: workspace,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          $('.workspace-created-message').show();
          $('.workspace-created-message .workspace-created-message__text').html(
            data.data.message
          );
          setTimeout(function () {
            location.reload(true);
          }, 1000);
        } else {
          alert(data.data);
        }
      });
  });

  $(document).on('click', '.js_view_pdf', function (e) {
    let action = 'view-edition-pdf-data';
    let post_type = 'edition';
    if ($(this).data('pdf-type') === 'edition') {
      action = 'view-edition-pdf-data';
      post_type = 'edition';
    } else if ($(this).data('pdf-type') === 'article') {
      action = 'view-article-pdf-data';
      post_type = 'article';
    }
    e.preventDefault();
    const pdf_view = new FormData();
    pdf_view.append(post_type + '_id', $(this).data(post_type + '-id'));
    pdf_view.append('action', action);
    const options = {
      method: 'POST',
      body: pdf_view,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // $('.pdf-view-data').html(data.data.html);
          $('#' + post_type + '-pdf').addClass('shown-pdf');
          $('#' + post_type + '-pdf .modal-dialog').addClass('modal-xl');
          $('#' + post_type + '-pdf .modal-body')
            .removeClass('p-5')
            .addClass('p-2')
            .html(
              '<embed class="w-100" src="' + data.data.pdf_file + '"></embed>'
            );
        } else {
          alert(data.data);
        }
      });
  });

  $(document).on('click', '.articles_created__close', function () {
    $(this).closest('.articles_created').remove();
    const dataTeam = new FormData();
    dataTeam.append('action', 'clear_articles_created');
    const options = {
      method: 'POST',
      body: dataTeam,
    };
    fetch(ajax_object.ajax_url, options).then((response) => response.json());
  });
});
