export const globals = {
  baseApiUrl: '/wp-json/ebony/v1',
};

export const prepareUrlParams = (params = {}) => {
  let urlParams = '';
  if (params) {
    let i = 0;
    for (let key in params) {
      // eslint-disable-next-line no-prototype-builtins
      params.hasOwnProperty(key)
        ? (urlParams += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false;
      i++;
    }
  }
  return urlParams;
};
