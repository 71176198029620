import apiFetch from '@wordpress/api-fetch';
// function displayLoadMore() {
//   const totalUsers = $('.users-grid').data('total');
//   const displayedUsers = $('.users-grid .js-srtable-grid__teammember').length;
//   if (displayedUsers === totalUsers) {
//     $('.users-load-more').hide();
//   } else {
//     $('.users-load-more').show();
//   }
// }

function updatePagination(pageCount) {
  const pages = Math.ceil(pageCount);
  const $pagination = $('.custom-pagination');

  if (pages <= 1) {
    $pagination.hide();
  } else {
    $pagination.show();
    $pagination.data('total-pages', pages);
    $pagination.find('.custom-pagination__total').html(pages);
    $pagination.find('.custom-pagination__input input').val(1);
  }
}

$(document).on('ready', function () {
  // displayLoadMore();

  $(document).on(
    'click',
    '.js-open-delete-user, .js-cancel-delete-user, .js-open-restore-user, .js-cancel-restore-user',
    function () {
      let isRestore = false;
      if ($(this).hasClass('js-open-restore-user')) {
        isRestore = true;
      }
      let user_id = $(this).closest('.srtable-grid__teammember').data('user');
      if (user_id) {
        $('#delete-user-popup-' + user_id + '.delete-user-popup').addClass(
          isRestore ? 'opened restore-content' : 'opened'
        );
      } else {
        $('.delete-user-popup').removeClass('opened restore-content');
      }
    }
  );

  /* global ajax_object */

  /* Users Sorting */
  $(document).on('click', '.js-srtable-filter-item', function () {
    $('.js-srtable-sort-icon')
      .removeClass('fa-sort-down fa-sort-up')
      .addClass('fa-sort'); //reset all sorting icon
    let searchValue = $('.users_top__filters .js-user-search').val();
    let filterType = $(this).data('filter');
    let filterMethod = $(this)
      .parents('.srtable-grid-filters')
      .data('filter_type');
    let order = 'DESC';
    if (!$(this).hasClass('active')) {
      $('.js-srtable-filter-item').removeClass('active clicked');
      $(this).addClass('active');
      $(this)
        .siblings('.js-srtable-sort-icon')
        .addClass('fa-sort-down')
        .removeClass('fa-sort');
    } else {
      if ($(this).hasClass('clicked')) {
        order = 'DESC';
        $(this).removeClass('clicked');
        $(this)
          .siblings('.js-srtable-sort-icon')
          .addClass('fa-sort-down')
          .removeClass('fa-sort');
      } else {
        order = 'ASC';
        $(this).addClass('clicked');
        $(this)
          .siblings('.js-srtable-sort-icon')
          .addClass('fa-sort-up')
          .removeClass('fa-sort-down');
      }
    }
    const isArchive = $(this)
      .closest('.srtable-grid__container')
      .data('archive');
    const orderParams = new FormData();
    const role = $('.js-filter-role').val();
    orderParams.append('action', filterMethod);
    orderParams.append('filter_type', filterType);
    orderParams.append('order', order);
    if (filterMethod === 'order_user') {
      orderParams.append('search', searchValue);
      orderParams.append('is_archive', isArchive);
    } else if (filterMethod === 'order_editions') {
      const workspace = $(this)
        .closest('.srtable-grid__container')
        .data('workspace');
      orderParams.append('workspace', workspace);
    }
    if (role) {
      orderParams.append('role', role);
    }
    const options = {
      method: 'POST',
      body: orderParams,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if ($('.tax-workspace').length) {
          $('#srtable-grid').html(data.html);
          $('.js-workspace-page')
            .addClass('filtered')
            .find('.custom-pagination__input input')
            .val(1);
        } else {
          $('#srtable-grid').html(data.html).data('total', data.users_count);
          // displayLoadMore();
          $('.js-users-page .custom-pagination__input input').val(1);
        }
      });
  });

  /* Users Search page */

  let timer1;
  $(
    '.users_top__filters .js-user-search, .filter-sidebar__item .js-user-search'
  ).on('keyup', function () {
    if (timer1) {
      clearTimeout(timer1);
    }
    const value = $(this).val();
    $('.js-user-search').val(value); //update sidebar or top filter seacrh input
    timer1 = setTimeout(function () {
      const dataTeam = new FormData();
      const isArchive = $('.srtable-grid__container').data('archive');
      dataTeam.append('action', 'search_user');
      dataTeam.append('search_value', value);
      dataTeam.append('is_archive', isArchive);
      const options = {
        method: 'POST',
        body: dataTeam,
      };
      fetch(ajax_object.ajax_url, options)
        .then((response) => response.json())
        .then((data) => {
          $('.js-srtable-filter-item').removeClass('active clicked');
          $('.js-srtable-sort-icon')
            .removeClass('fa-sort-down fa-sort-up')
            .addClass('fa-sort'); //reset all sorting icon
          $('#srtable-grid').html(data.html).data('total', data.users_count);
          updatePagination(data.users_count / 10);
        });
    }, 500);
  });

  /* Manage Invited User */

  $(document).on('click', '.invited_user_manage', function (e) {
    if (e.target.parentElement.className !== 'manage-options') {
      $(this).toggleClass('active');
      //$(this).find('.resend-invite').html('Resend Invite');
    }
  });

  $(document).on('click', function (event) {
    if (
      !$(event.target).closest('.invited_user_manage, .archived_user_manage')
        .length
    ) {
      $('.invited_user_manage, .archived_user_manage').removeClass('active');
    }
  });

  /* Manage Archived User */

  $(document).on('click', '.archived_user_manage', function (e) {
    if (e.target.parentElement.className !== 'manage-options') {
      $(this).toggleClass('active');
      //$(this).find('.resend-invite').html('Resend Invite');
    }
  });

  /* Resend invite to User */

  $(document).on('click', '.resend-invite', function () {
    const $this = $(this);
    const body = new FormData();
    const user_email = $this
      .closest('.srtable-grid__teammember')
      .find('.srtable-grid__email')
      .html();
    body.append('email', user_email);
    apiFetch({
      path: `/wp-json/ebony/v1/request-verify-link`,
      method: 'POST',
      body,
    })
      .then((response) => {
        if (response) {
          $('.user-created-message').show();
          $('.user-created-message .user-created-message__text').html(
            'Resend link has been sent to the user'
          );
          setTimeout(function () {
            $('.user-created-message').hide();
          }, 1500);
        }
      })
      .catch((response) => {
        console.log('response', response); //eslint-disable-line
      });
  });

  /* Delete user */
  $(document).on('click', '.cancel-invite', function () {
    const $this = $(this);
    const body = new FormData();
    const user_id = $(this)
      .closest('.srtable-grid__teammember')
      .find('.js-srtable-grid__teammember')
      .data('user');
    const user_email = $(this)
      .closest('.srtable-grid__teammember')
      .find('.srtable-grid__email')
      .html();
    body.append('user_id', user_id);
    body.append('user_email', user_email);

    apiFetch({
      path: `/wp-json/ebony/v1/delete-user`,
      method: 'POST',
      body,
    })
      .then((response) => {
        if (response) {
          $('.user-created-message').show();
          $('.user-created-message').addClass('user-deleted');
          $('.user-created-message i').removeClass('fa-check').addClass('fa-x');
          $('.user-created-message .user-created-message__text').html(response);
          setTimeout(function () {
            location.reload(true);
          }, 1500);
        }
      })
      .catch((response) => {
        $this.html(
          '<span class="delete-user-submited">' + response.error + '</span>'
        );
      });
  });

  /* Load More Users */
  // $(document).on('click', '.js-load-users', function () {
  //   const displayedUsers = $('.users-grid .js-srtable-grid__teammember').length;
  //   const searchValue = $('.users_top__filters .js-user-search').val();
  //   let $activeSorting = $('.js-srtable-filter-item.active');
  //   let filterType = '';
  //   let order = 'ASC';
  //   if ($activeSorting.length) {
  //     filterType = $('.js-srtable-filter-item.active').data('filter');
  //
  //     if ($activeSorting.hasClass('clicked')) {
  //       order = 'ASC';
  //     } else {
  //       order = 'DESC';
  //     }
  //   }
  //   const isArchive = $(this)
  //     .closest('.srtable-grid__container')
  //     .data('archive');
  //   const dataTeam = new FormData();
  //   dataTeam.append('action', 'load_more_user');
  //   dataTeam.append('search_value', searchValue);
  //   dataTeam.append('offset', displayedUsers);
  //   dataTeam.append('filter_type', filterType);
  //   dataTeam.append('order', order);
  //   dataTeam.append('is_archive', isArchive);
  //   const options = {
  //     method: 'POST',
  //     body: dataTeam,
  //   };
  //   fetch(ajax_object.ajax_url, options)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       $('#srtable-grid').append(data.html);
  //       // displayLoadMore();
  //     });
  // });

  /* Users pagination */
  $(document).on(
    'change',
    '.js-users-page .custom-pagination__input input',
    function () {
      const page = $('.js-users-page .custom-pagination__input input').val();
      const searchValue = $('.users_top__filters .js-user-search').val();
      let $activeSorting = $('.js-srtable-filter-item.active');
      let filterType = '';
      let order = 'ASC';
      if ($activeSorting.length) {
        filterType = $('.js-srtable-filter-item.active').data('filter');

        if ($activeSorting.hasClass('clicked')) {
          order = 'ASC';
        } else {
          order = 'DESC';
        }
      }
      const isArchive = $(this)
        .closest('.srtable-grid__container')
        .data('archive');
      const dataTeam = new FormData();
      dataTeam.append('action', 'load_users_page');
      dataTeam.append('search_value', searchValue);
      dataTeam.append('page', page);
      dataTeam.append('filter_type', filterType);
      dataTeam.append('order', order);
      dataTeam.append('is_archive', isArchive);
      const options = {
        method: 'POST',
        body: dataTeam,
      };
      fetch(ajax_object.ajax_url, options)
        .then((response) => response.json())
        .then((data) => {
          $('#srtable-grid').html(data.html);
        });
    }
  );

  $(document).on('click', '.users-invited__close', function () {
    $(this).closest('.users-invited').remove();
    const dataTeam = new FormData();
    dataTeam.append('action', 'clear_invited_user');
    const options = {
      method: 'POST',
      body: dataTeam,
    };
    fetch(ajax_object.ajax_url, options).then((response) => response.json());
  });

  /* Edit/Archive user */
  $(document).on('click', '.js-srtable-grid__teammember', function (e) {
    let ignoreClassList = [
      'users-grid__role',
      'manage-options',
      'invited_user_manage',
      'archived_user_manage',
      'srtable-grid__role',
      'srtable-grid__assignment',
    ];

    //if clicked not on three dots :)
    if (!ignoreClassList.includes(e.target.parentElement.className)) {
      let userID = $(this).data('user');
      let isArchivedUser = false;

      if (
        $(this).closest('.srtable-grid__container').data('archive') === true
      ) {
        isArchivedUser = true;
      }
      const dataUser = new FormData();
      dataUser.append('action', 'edit-archive-user');
      dataUser.append('userID', userID);

      if (isArchivedUser) {
        dataUser.append('archived_user_restore', 'true');
      }
      const options = {
        method: 'POST',
        body: dataUser,
      };
      fetch(ajax_object.ajax_url, options)
        .then((response) => response.json())
        .then((data) => {
          $('.users_top').html(data.top_part);
          $('.main').html(data.content);
          window.scrollTo(0, 0);
        });
    }
  });

  $(document).on('click', '.js-cancel-user-edit', function () {
    const dataForm = new FormData();
    dataForm.append('action', 'cancel-user-edit');
    if ($(this).hasClass('archived-list')) {
      dataForm.append('show_archive_list', 'true');
    }
    const options = {
      method: 'POST',
      body: dataForm,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        $('.users_top').html(data.top_part);
        $('.main').html(data.content);
        window.scrollTo(0, 0);
      });
  });

  $(document).on('click', '.js-edit-user-name', function () {
    $(this).toggleClass('active');

    /* Validate input length */
    $('.register-new-user .register-new-user__name input').on(
      'input',
      function () {
        if ($(this).val().length > 64) {
          let value = $(this).val();
          $(this).val(value.substring(0, 64));
        }
      }
    );
  });

  $(document).on('click', '.js-edit-user', function (e) {
    e.preventDefault();
    const dataForm = new FormData($('#edit-user')[0]);
    dataForm.append('action', 'custom-edit-user');
    const options = {
      method: 'POST',
      body: dataForm,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          $('.user-created-message').show();
          $('.user-created-message .user-created-message__text').html(
            data.data.message
          );
          setTimeout(function () {
            $('.user-created-message').hide();
            location.reload(true);
          }, 1000);
        } else {
          alert(data.data);
        }
      });
  });

  const clearArchived = () => {
    $('.users-archived').each(function () {
      $(this).remove();
    });
    const dataTeam = new FormData();
    dataTeam.append('action', 'clear_archived_user');
    const options = {
      method: 'POST',
      body: dataTeam,
    };
    fetch(ajax_object.ajax_url, options).then((response) => response.json());
  };

  $(document).on('click', '.users-archived__close', function () {
    clearArchived();
  });

  if ($('.users-archived').length) {
    setTimeout(function () {
      clearArchived();
    }, 3000);
  }

  /* Delete archived user */

  $(document).on('click', '.js-delete-user', function (e) {
    e.preventDefault();
    const dataForm = new FormData();
    dataForm.append('action', 'delete_archive_user');
    dataForm.append('user_id', $(this).data('user'));
    const options = {
      method: 'POST',
      body: dataForm,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // $('.delete-user-popup .delete-user-popup__container').html(
          //   data.data.message
          // );
          // setTimeout(function () {
          let domain = document.location.origin;
          document.location.href = domain + '/admin/users-archive/';
          // }, 1000);
        } else {
          alert(data.data);
        }
      });
  });

  const clearDeleted = () => {
    $('.users-deleted').each(function () {
      $(this).remove();
    });
    const dataTeam = new FormData();
    dataTeam.append('action', 'clear_deleted_user');
    const options = {
      method: 'POST',
      body: dataTeam,
    };
    fetch(ajax_object.ajax_url, options).then((response) => response.json());
  };

  $(document).on('click', '.users-deleted__close', function () {
    clearDeleted();
  });

  if ($('.users-deleted').length) {
    setTimeout(function () {
      clearDeleted();
    }, 3000);
  }

  /* Restore archived user */

  $(document).on('click', '.js-quick-restore-user', function (e) {
    e.preventDefault();
    const dataForm = new FormData();
    dataForm.append('action', 'quick_restore_user');
    dataForm.append('user_id', $(this).data('user'));
    const options = {
      method: 'POST',
      body: dataForm,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // $('.delete-user-popup .delete-user-popup__container').html(
          //   data.data.message
          // );
          // setTimeout(function () {
          let domain = document.location.origin;
          document.location.href = domain + '/admin/users-archive/';
          // }, 1000);
        } else {
          alert(data.data);
        }
      });
  });

  const clearRestored = () => {
    $('.users-restored').each(function () {
      $(this).remove();
    });
    const dataTeam = new FormData();
    dataTeam.append('action', 'clear_restored_user');
    const options = {
      method: 'POST',
      body: dataTeam,
    };
    fetch(ajax_object.ajax_url, options).then((response) => response.json());
  };

  $(document).on('click', '.users-restored__close', function () {
    clearRestored();
  });

  if ($('.users-restored').length) {
    setTimeout(function () {
      clearRestored();
    }, 3000);
  }

  $(document).on('click', '.js-apply_search', function () {
    $(this).toggleClass('active');
    $('.filter-sidebar').toggleClass('active');
  });
  $(document).on('click', '.close-filter', function () {
    $('.js-apply_search, .filter-sidebar').removeClass('active');
  });
  $(document).on(
    'click',
    '.js-sidebar-filter-role .custom-select__item',
    function () {
      const value = $(this).data('value');
      $('input.js-filter-role').val(value).trigger('change');
    }
  );

  $('.js-filter-role').on('change', function () {
    const selectValue = $(this).val();

    const isArchive = $('.srtable-grid__container').data('archive');
    // const orderParams = new FormData();
    // orderParams.append('action', 'order_user');
    let filterType = 'display_name';
    let order = 'ASC';
    let searchValue = $('.filter-sidebar .js-user-search').val();
    if ($('.js-srtable-filter-item.active').length) {
      filterType = $('.js-srtable-filter-item.active').data('filter');
      order = 'DESC';
    }
    if ($('.js-srtable-filter-item.active.clicked').length) {
      order = 'ASC';
    }
    const filterParams = new FormData();
    filterParams.append('action', 'order_user');
    filterParams.append('filter_type', filterType);
    filterParams.append('order', order);
    filterParams.append('search', searchValue);
    filterParams.append('is_archive', isArchive);
    if (selectValue !== '') {
      filterParams.append('role', selectValue);
    }
    const options = {
      method: 'POST',
      body: filterParams,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        $('#srtable-grid').html(data.html).data('total', data.users_count);
        // displayLoadMore();
        $('.js-users-page .custom-pagination__input input').val(1);
      });
  });

  $('.clear-filters').on('click', function () {
    window.location.reload();
  });

  /* Load user's Article List in popup */
  $(document).on('click', '.js-open-assignment-list:not(.loaded)', function () {
    const userID = $(this).data('user');
    const articleStatus = $(this).data('article-status');
    const filterParams = new FormData();
    filterParams.append('action', 'load_user_articles');
    filterParams.append('userID', userID);
    filterParams.append('articleStatus', articleStatus);

    const options = {
      method: 'POST',
      body: filterParams,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        $(this)
          .siblings('.modal')
          .find('.assignment_container')
          .html(data.html)
          .addClass('loaded');
      });
  });
});
