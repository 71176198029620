import update from 'immutability-helper';
import React, { useCallback, useEffect, useState } from 'react';
import Card from './card.js';
import PropTypes from 'prop-types';

const Container = ({
  className,
  cardClassName,
  images,
  setSelectedImages,
  setImageToEdit,
  imageToEdit,
  setScalePoint,
  scalePoint,
  setImageToDelete,
  setOpenWarning,
  setImageToEditIndex,
  setSelectedImage,
  setImagesArray,
}) => {
  const [cards, setCards] = useState([]);
  useEffect(() => {
    setCards(images);
  }, [images]);

  useEffect(() => {
    let imagesString = '';
    let arrayForOrder = '';

    cards.map((card, key) => {
      imagesString += (key !== 0 ? ',' : '') + card.name;
      arrayForOrder += (key !== 0 ? ',' : '') + card.url;
    });
    if (setImagesArray) {
      setImagesArray(arrayForOrder);
    }
    if (setSelectedImages) {
      setSelectedImages(imagesString); // update images order
    }
  }, [cards]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
    },
    [cards]
  );

  const renderCard = useCallback(
    (card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
          cardClassName={cardClassName}
          cards={images}
          setCards={setCards}
          setImageToEdit={setImageToEdit}
          imageToEdit={imageToEdit}
          setScalePoint={setScalePoint}
          scalePoint={scalePoint}
          setImageToDelete={setImageToDelete}
          setOpenWarning={setOpenWarning}
          setImageToEditIndex={setImageToEditIndex}
          setSelectedImage={setSelectedImage}
        />
      );
    },
    [cards, imageToEdit, scalePoint]
  );

  return (
    <>
      <div className={className}>
        {cards.map((card, i) => renderCard(card, i))}
      </div>
    </>
  );
};

Container.propTypes = {
  className: PropTypes.string,
  cardClassName: PropTypes.string,
  images: PropTypes.array,
  setSelectedImages: PropTypes.func,
  setImageToEdit: PropTypes.func,
  imageToEdit: PropTypes.string,
  scalePoint: PropTypes.number,
  setScalePoint: PropTypes.func,
  setImageToDelete: PropTypes.func,
  setOpenWarning: PropTypes.func,
  setImageToEditIndex: PropTypes.func,
  setSelectedImage: PropTypes.func,
  setImagesArray: PropTypes.func,
};

export default Container;
