$(document).on('ready', function () {
  /* global ajax_object */

  /* Show Permissions on role select change */
  //validateForm();
  statusChange();

  $(document).on(
    'change paste keyup',
    '.register-new-article__title input, .register-new-article__author input',
    function () {
      //validateForm();
    }
  );

  $(document).on('change', '#article_status', function () {
    statusChange();
  });

  /* Save Article as draft */
  $('.js-save-draft-article a').on('click', function () {
    //let formValues = $(document).find('#add-new-article').serializeArray();
    let allowSubmit = true;
    // formValues.forEach((element) => {
    //   if (
    //     (element.name == 'title' && element.value === '') ||
    //     element.length < 3
    //   ) {
    //     $('.register-new-article__title input')
    //       .closest('.input-field')
    //       .addClass('with-error')
    //       .append(
    //         '<div class="error-msg"><span><strong>Please provide a valid value.</strong></span></div>'
    //       );
    //     allowSubmit = false;
    //   } else if (element.name == 'author' && element.value === '') {
    //     $('.register-new-article__author input')
    //       .closest('.input-field')
    //       .addClass('with-error')
    //       .append(
    //         '<div class="error-msg"><span><strong>Please provide a valid value.</strong></span></div>'
    //       );
    //     allowSubmit = false;
    //   }
    // });

    if (allowSubmit) {
      const formData = new FormData($(document).find('#add-new-article')[0]);
      formData.append('action', 'add_article');
      formData.append('save_draft', 'true');
      formData.append('status', $('#article_status').find(':selected').val());
      formData.append(
        'assigned_user',
        $('#article_assigned_user').data('user')
      );
      const options = {
        method: 'POST',
        body: formData,
      };

      fetch(ajax_object.ajax_url, options)
        .then((response) => response.json())
        .then((data) => {
          $(this).find('.spinner-border').removeClass('d-none'); // show loader
          if (data.success) {
            $('.article-created-message').show();
            $('.article-created-message .article-created-message__text').html(
              data.data.message
            );
            setTimeout(function () {
              if (data.data.redirect) {
                window.location.href = data.data.redirect;
              } else {
                location.reload(true);
              }
            }, 1000);
          } else {
            $(this).find('.spinner-border').addClass('d-none'); // hide loader
            alert(data.data);
          }
        });
    }
  });

  const articleAssignUser = $('.js-article_assign_user');
  const articleAssignSelect = $('.article_assign__select');

  articleAssignUser.on('click', function (event) {
    if (event.target.parentElement.id === 'article_assigned_user') {
      articleAssignSelect.toggleClass('d-none');
    }
  });

  $(document).on('click', function (event) {
    if (
      !articleAssignUser.is(event.target) &&
      !articleAssignUser.has(event.target).length
    ) {
      articleAssignSelect.addClass('d-none');
    }
  });
});

$('.js_delete_article').on('click', function (e) {
  e.preventDefault();
  const article = new FormData();
  article.append('article_id', $(this).data('article-id'));
  article.append('edition_id', $(this).data('edition-id'));
  article.append('action', 'delete-article');
  const options = {
    method: 'POST',
    body: article,
  };
  fetch(ajax_object.ajax_url, options)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        $('#article-delete .modal-body').html(data.data.message);
        $('#article-delete').addClass('removed'); // to avoid alert message on page leave
        setTimeout(function () {
          if ($('#article-delete').data('edition-link')) {
            window.location.href = $('#article-delete').data('edition-link');
          } else {
            location.reload(true);
          }
        }, 1000);
      } else {
        alert(data.data);
      }
    });
});

$('#article-delete').on('show.bs.modal', function (event) {
  let button = $(event.relatedTarget);
  let article_id = button.data('aticle-id');
  let edition_id = button.data('edition-id');
  let modal = $(this);
  modal
    .find('.modal-body .js_delete_article')
    .attr('data-article-id', article_id)
    .attr('data-edition-id', edition_id);
});

const validateForm = () => {
  validateInput(
    $('.register-new-article__title input, .register-new-article__author input')
  );

  if ($('.with-error').length === 0) {
    $('button[type="submit"]').attr('disabled', false);
  } else {
    $('button[type="submit"]').attr('disabled', true);
  }
};

const statusChange = () => {
  const value = $('#article_status').val();
  $('.article_set_status').attr('set_status', value);
};

const validateInput = (input) => {
  input.each(function () {
    if ($(this).val() === '' || $(this).val().length < 3) {
      if (!$(this).closest('.input-field').hasClass('with-error')) {
        $(this)
          .closest('.input-field')
          .addClass('with-error')
          .append(
            '<div class="error-msg"><span>Please provide a valid value.</span></div>'
          );
      }
    } else {
      $(this)
        .closest('.input-field')
        .removeClass('with-error')
        .find('.error-msg')
        .remove();
    }
  });
};

$(document).on('click', '.images-grid > .article-page > img', function () {
  $('.raw-data').addClass('d-none');
  const image_key_id = $(this).attr('image-key');
  $('.raw-data[data-image-key="' + image_key_id + '"]').removeClass('d-none');
});
