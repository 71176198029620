import React, { useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

const style = {
  cursor: 'move',
};

const Card = ({
  id,
  text,
  index,
  moveCard,
  cardClassName,
  cards,
  setImageToEdit,
  imageToEdit,
  setScalePoint,
  scalePoint,
  setImageToDelete,
  setOpenWarning,
  setImageToEditIndex,
  setSelectedImage,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const removeImage = (e) => {
    let imageName = e.target.getAttribute('data-name');
    setImageToDelete(imageName);
    setOpenWarning(true);
  };

  useEffect(() => {
    const imageContainer = document.getElementsByClassName(
      'article_image__to_edit'
    )[0];
    if (imageContainer) {
      imageContainer.getElementsByTagName('img')[0].style.transform =
        'scale(' + scalePoint + ')';
    }
  }, [scalePoint]);

  const selectNextImage = () => {
    const selectedImage = document.getElementsByClassName(
      'article-page active'
    )[0];

    const nextEl = selectedImage.nextSibling;
    if (nextEl) {
      selectedImage.classList.remove('active');
      selectedImage.getElementsByTagName('img')[0].removeAttribute('class');
      nextEl.classList.add('active');
      nextEl
        .getElementsByTagName('img')[0]
        .setAttribute('class', 'image-active');
      const imgSrc = nextEl.getElementsByTagName('img')[0].src;
      setImageToEdit(imgSrc);
      setScalePoint(1);
      setSelectedImage(
        nextEl.getElementsByTagName('img')[0].getAttribute('image-key')
      );
    }
  };

  const selectPrevImage = () => {
    const selectedImage = document.getElementsByClassName(
      'article-page active'
    )[0];
    const prevEl = selectedImage.previousSibling;
    if (prevEl) {
      selectedImage.classList.remove('active');
      selectedImage.getElementsByTagName('img')[0].removeAttribute('class');
      prevEl.classList.add('active');
      prevEl
        .getElementsByTagName('img')[0]
        .setAttribute('class', 'image-active');
      const imgSrc = prevEl.getElementsByTagName('img')[0].src;
      setImageToEdit(imgSrc);
      setScalePoint(1);
      setSelectedImage(
        prevEl.getElementsByTagName('img')[0].getAttribute('image-key')
      );
    }
  };

  useEffect(() => {
    if (cards) {
      const trashIcon = document.getElementsByClassName('remove-image-icon');

      /* Navigation buttons */
      const nextImageArrow =
        document.getElementsByClassName('js-next-edit-image');

      for (let i = 0; i < nextImageArrow.length; i++) {
        if (nextImageArrow[i].classList.value.indexOf('active') === -1) {
          nextImageArrow[i].classList.add('active');
          nextImageArrow[i].addEventListener('click', () => selectNextImage());
        }
      }

      const prevImageArrow =
        document.getElementsByClassName('js-prev-edit-image');

      for (let i = 0; i < prevImageArrow.length; i++) {
        if (prevImageArrow[i].classList.value.indexOf('active') === -1) {
          prevImageArrow[i].classList.add('active');
          prevImageArrow[i].addEventListener('click', () => selectPrevImage());
        }
      }

      if (trashIcon.length) {
        for (let i = 0; i < trashIcon.length; i++) {
          trashIcon[i].addEventListener('click', removeImage);
        }

        return () => {
          for (let i = 0; i < trashIcon.length; i++) {
            trashIcon[i].removeEventListener('click', removeImage);
          }

          for (let i = 0; i < nextImageArrow.length; i++) {
            nextImageArrow[i].removeEventListener('click', selectNextImage);
          }

          for (let i = 0; i < prevImageArrow.length; i++) {
            prevImageArrow[i].removeEventListener('click', selectPrevImage);
          }
        };
      }
    }
  }, [cards, imageToEdit, scalePoint]);

  const getSiblings = function (e) {
    // for collecting siblings
    let siblings = [];
    // if no parent, return no sibling
    if (!e.parentNode) {
      return siblings;
    }
    // first child of the parent node
    let sibling = e.parentNode.firstChild;
    // collecting siblings
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== e) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }
    return siblings;
  };

  const toggleActive = (el) => {
    if (el.target.classList.value.indexOf('remove-image-icon') === -1) {
      let imgSrc = '';
      const classList = ref.current.classList;
      let siblings = getSiblings(ref.current);
      // const data_image_key = ref.current.getAttribute('data-image-key');
      // const ocr_text = document.querySelectorAll(
      //   'div.image-indexes[data-image-key="${data_image_key}""]'
      // );
      // console.log('data_image_key', data_image_key); //eslint-disable-line
      // console.log('ocr_text', ocr_text); //eslint-disable-line

      setImageToEditIndex(
        ref.current.getElementsByTagName('img')[0].getAttribute('image-index')
      );
      // oct_text.removeClass('d-none');

      //Remove active class from all sibling images
      siblings.map((e) => e.classList.remove('active'));
      //Remove active class if clicked on the same element
      if (classList.value.indexOf('active') >= 0) {
        ref.current.classList.remove('active');
        ref.current.getElementsByTagName('img')[0].removeAttribute('class');
        imgSrc = '';
      } else {
        //Add active class
        ref.current.classList.add('active');
        ref.current
          .getElementsByTagName('img')[0]
          .setAttribute('class', 'image-active');
        imgSrc = ref.current.getElementsByTagName('img')[0].src;
      }
      setImageToEdit(imgSrc);
      setSelectedImage(
        ref.current.getElementsByTagName('img')[0].getAttribute('image-key')
      );
    }
  };

  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={handlerId}
      data-image-key={`${index}`}
      className={cardClassName}
      dangerouslySetInnerHTML={{ __html: text }}
      onClick={toggleActive}
    />
  );
};

Card.propTypes = {
  id: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  index: PropTypes.number,
  moveCard: PropTypes.func,
  cardClassName: PropTypes.string,
  cards: PropTypes.array,
  setCards: PropTypes.func,
  setImageToEdit: PropTypes.func,
  imageToEdit: PropTypes.string,
  scalePoint: PropTypes.number,
  setScalePoint: PropTypes.func,
  setImageToDelete: PropTypes.func,
  setOpenWarning: PropTypes.func,
  setImageToEditIndex: PropTypes.func,
  setSelectedImage: PropTypes.func,
};

export default Card;
