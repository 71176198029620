/* Import the whole bootstrap js */
// import 'bootstrap';

/* Or just a specific parts of it */
// import 'bootstrap/js/src/alert';
// import 'bootstrap/js/src/button';
// import 'bootstrap/js/src/carousel';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/popover';
// import 'bootstrap/js/src/scrollspy';
// import 'bootstrap/js/src/tab';
// import 'bootstrap/js/src/toast';
import 'bootstrap/js/src/tooltip';
/**
 * in this case, util scripts is required
 * @see https://getbootstrap.com/docs/4.6/getting-started/javascript/#util
 */
import 'bootstrap/js/src/util';
