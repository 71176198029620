/*
 * this factory adds react-hot-loader with redbox-react
 * and the react-redux provider
 * */
import React from 'react';
import ReactDOM from 'react-dom';

export default class DomFactory {
  /**
   * Injects a react component
   *
   * @param {Object}      module - The react component
   * @param {Object}      props  - Props to initiate component with
   * @param {HTMLElement} target - The target element to inject to
   */
  inject(module, props = {}, target) {
    if (target) {
      ReactDOM.render(React.createElement(module, props || {}), target);
    } else {
      // eslint-disable-next-line
      console.warn('RHW07', 'Target element is null or undefined.');
    }
  }

  /**
   *  Disposes a react component
   *
   * @param {HTMLElement} target - The target element to dispose
   */
  dispose(target) {
    if (target) {
      ReactDOM.unmountComponentAtNode(target);
    }
  }
}
