$(document).on('ready', function () {
  /* global ajax_object */

  $(document).on(
    'click',
    '.js-open-popup-archive-user, .js-cancel-archive-user',
    function () {
      $('.archive-user-popup').toggleClass('opened');
    }
  );

  $(document).on('click', '.js-archive-user', function (e) {
    e.preventDefault();
    const dataForm = new FormData();
    dataForm.append('action', 'archive-user');
    dataForm.append('user_id', $(this).data('user'));
    const options = {
      method: 'POST',
      body: dataForm,
    };
    fetch(ajax_object.ajax_url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // $('#archive-user-popup .archive-user-popup__container').html(
          //   data.data.message
          // );
          // setTimeout(function () {
          let domain = document.location.origin;
          document.location.href = domain + '/admin/users/';
          // }, 1000);
        } else {
          alert(data.data);
        }
      });
  });

  //Open Restore/Delete popup on from details page

  $(document).on(
    'click',
    '.js-open-restore-user--details, .js-open-delete-user--details, .js-cancel-delete-user, .js-cancel-restore-user',
    function () {
      let isRestore = false;
      if ($(this).hasClass('js-open-restore-user--details')) {
        isRestore = true;
      }
      $('.delete-user-popup').toggleClass(
        isRestore ? 'opened restore-content' : 'opened'
      );
    }
  );
});
